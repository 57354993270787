@import "./colors";

#vote {
    position: relative;
    user-select: none;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    
    #question-wrapper {
        position: relative;
        width: 100%;
        
        #question-nav {
            position: sticky;
            display: block;
            overflow-y: auto;
            background-color: $white;
            width: 100%;
            height: 100%;
            max-height: 80vh;
            top: 4em;
            z-index: 1;

            .collapsible-wrapper {
                position: sticky;
                top: 4em;
            }

            button.question-button {
                width: 100%;
                cursor: pointer;

                &.disabled {
                    &:hover {
                        background-color: $buttonBackgroundHover;
                        color: $buttonBackgroundHoverText;
                    }

                    &:active {
                        background-color: $buttonBackgroundActive;
                        color: $buttonBackgroundActiveText;
                        box-shadow: none;
                    }

                    &.selected {
                        background-color: $buttonBackgroundSelected;
                        color: $buttonBackgroundSelectedText;
                        box-shadow: 2px 2px 6px $buttonBackgroundSelected;
                    }
                }
            }

            .legend {
                &-cards {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    .card {
                        width: 50%;
                        font-size: .8em;
                        width: initial;
                        height: initial;

                        &.display-only {
                            &:hover, &:active {
                                background-color: inherit;
                            }
                        }
                    }
                }
            }
        }

        #award-question-wrapper {
            position: relative;
            overflow: clip;
            width: 100%;
            height: 100%;
            padding: 0 .5em;
    
            div.award-question {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                transition: all .25s;
                transition-delay: .25s;
                transform: translateX(calc(-100% * var(--viewing)));
    
                section.question {
                    transition: all .25s;
                    width: 100%;
                    flex: 0 0 auto;
                    opacity: 0;
                    height: 0;
    
                    &.active {
                        transition-delay: .5s;
                        opacity: 1;
                        height: 100%;
                    }
    
                    .header {
                        position: sticky;
                        text-align: center;
                        padding: .5em;
                        top: 5.7em;
                        z-index: 1;
                        background-color: $white;
                    }
    
                    .description {
                        text-align: center;
                        white-space: pre-line;
                        padding: .5em 0;
                    }
    
                    .locked-response {
                        padding: 1em; 
                        
                        .current-vote {
                            text-align: center;
                            
                            .selectable-answer {
                                width: 100%;
                            }
                        }
                    }
    
                    .search-bar {
                        width: 100%;
                        text-align: center;
    
                        input {
                            width: 100%;
                        }
                    }
    
                    .current-vote {
                        padding: 1em 0;
    
                        .selectable-answer {
                            pointer-events: none;
                        }
                    }
    
                    .text-response {
                        position: relative;
                        width: 100%;
                        height: 100%;
                     
                        textarea {
                            display: block;
                            position: relative;
                            width: 100%;
                            height: calc(100vh - 30em);
                            resize: none;
    
                            &:focus {
                                border: 1px solid black;
                                border-bottom: none;
                                outline: none;
                            }
                        }
                    }
    
                    .selectable-answers {
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;
                        align-items: stretch;
                        justify-content: space-around;
                        padding: 0 .25em;
                        overflow-y: auto;
                        // max-height: calc(100vh - 20em);
                        
                        .selectable-answer {
                            display: block;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            width: 100%;
                            text-align: center;
    
                            sub {
                                vertical-align: top;
                            }
    
                            &.text {
                                display: flex;
                                line-height: 3em;
    
                                &.sub {
                                    line-height: 2em;
                                }
                            }
    
                            &.team {
                                &.image {
                                    height: auto;
                                    position: relative;
                                    padding: 0 0 .5em 0;
                                    user-select: none;
                                    
                                    .photo {
                                        position: relative;
                                        width: 100%;
                                        height: auto;
    
                                        img {
                                            width: 100%;
                                            height: auto;
                                            max-height: 100%;
                                            border-radius: .5em .5em 0 0;
                                            pointer-events: none;
                                        }
                                    }
                                }
                            }
    
                            &.teamplayer {
                                &.image {
                                    position: relative;
    
                                    img {
                                        width: auto;
                                        height: 100%;
                                        max-height: 10em;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .nav-buttons {
        position: sticky; 
        display: flex;
        justify-content: space-evenly;
        background-color: white;
        margin: auto;
        bottom: 0;
        left: 50%;
        width: 100%;
        padding: .5em;
        z-index: 1;
        // margin-top: .5em;

        button {
            width: 10em;
            padding: .75em 0;
        }
    }
}

@media screen and (min-width: 768px) {
    #vote {
        #question-wrapper {
            display: flex;

            #question-nav { 
                flex: 1 0 auto;
                width: 100%;
                max-width: 33%;

                .collapsible-wrapper {

                    .flipper {
                        display: none;
                    }
                    
                    &.hide {
                        .content {
                            grid-template-rows: 1fr;
                        }
                    }
                }
            }
            
            #award-question-wrapper {
                max-width: 66%;

                div.award-question {
                    section.question {
                        .header {
                            top: 4em;
                        }
                        .current-vote {
                            .selectable-answers {
                                .selectable-answer {
                                    max-width: 100%;
                                }
                            }
                        }

                        .selectable-answers {
                            .selectable-answer {
                                flex: 0 0 auto;
                            }
                        }

                        .locked-response { 
                            .current-vote {
                                .selectable-answers {
                                    .selectable-answer {
                                        &.team {
                                            &.image {
                                                max-width: 80vw;
                                                width: 80%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    #vote {
        #question-wrapper {


            #award-question-wrapper {
                div.award-question {
                    section.question {
                        .selectable-answers {
                            .selectable-answer {
                                max-width: 50%;
                                width: calc(50% - .5em);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1268px) {
    #vote {
        #question-wrapper {

            #award-question-wrapper {
                div.award-question {
                    section.question {
                        .selectable-answers {
                            .selectable-answer {
                                max-width: 33%;
                                width: calc(33% - .25em);

                                &.team {
                                    &.image {
                                        max-width: 50%;
                                        width: calc(50% - .25em);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
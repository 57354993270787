.question-votes {
    .question-division {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
    }
}


@media screen and (min-width: 768px) {
    .question-votes {
        .question-division {
            &.size-2 {
                .card {
                    width: 48%;
                }
            }

            &.size-3 {
                .card {
                    width: 32%;
                }
            }
        }    
    }
}

@import "./colors";

.question-votes {
    width: 100%;
    position: relative;
    
    .card {
        width: 100%;
        justify-content: center;
        margin: .25em;

        &-header {
            display: block;
            text-align: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: bold;
            padding: 0 0 .5em 0;
            border-bottom: 1px solid $black;
        }
    }

    .nominees {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .nominee {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content:center;
            flex-wrap: nowrap;
            flex-direction: row;
            margin-top: .5em;
            padding: .25em .5em;
            text-align: center;
            transition: all .5s;
            border-radius: 1em;

            aside {
                width: 10%;
                padding-right: 2em;
            }
            
            .nominee-name {
                min-width: 70%;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                div, sub {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .nominee-vote {
                width: 10%;
                text-align: right;
            }

            .trophy {
                width: 2em;
                height: 2em;

                .icon {
                    height: 100%;
                    width: 100%;

                    &:hover {
                        stroke: $iconHover;
                        fill: $iconHover;
                    }
                }
            }

            &.winner {
                background-color: rgba($buttonBackgroundSelected, .8);
                color: $buttonBackgroundSelectedText;

                .trophy {
                    .icon {
                        stroke: gold;
                        fill: gold;

                        &:hover {
                            stroke: $iconHover;
                            fill: $iconHover;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .question-votes {
        .card {
            width: calc(50% - .5em);

            .nominees {
                width: 100%;

                .nominee {
                    width: 100%;
                }
            }
        }

        .nominees {
            width: 100%;

            .nominee {
                width: 75%;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .question-votes {
        .card {
            width: calc(33% - .5em);

            .nominees {
                width: 100%;

                .nominee {
                    width: 100%;
                }
            }
        }

        .nominees {
            width: 100%;

            .nominee {
                width: 50%;
            }
        }
    }
}
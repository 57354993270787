@import "./colors";

.text-response {
    .textarea-footer {
        transition: all .5s;
        font-size: .75em;
        background-color: darken($backgroundColor, 10%);
        padding: .5em 1em;
        text-align: right;
        border-radius: 0 0 .25em .25em;
    }

    &.good {
        .textarea-footer {
            background-color: $green;
            color: $white;
        }
    }

    &.bad {
        .textarea-footer {
            background-color: $red;
            color: $white;
        }
    }
}
@import "./colors";

.tab-group {
    position: relative;
    display: flex;
    padding-top: 1em;
    user-select: none;
    border-bottom: 1px solid $lightGrey;

    .tab {
        padding: .5em 1em;
        box-shadow: none;
        border-radius: .5em .5em 0px 0px;
        margin: 0;
        margin-right: .1em;
        border: 1px solid $lightGrey;
        
        &.active {
            background-color: $buttonBackgroundActive;
            color: $buttonBackgroundActiveText;
        }
    }
}
@import "./colors";

.labeler {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    line-height: 1em;
    
    padding:  1em .5em;
    border-width: .2em;
    border-radius: .25em;
    box-shadow: 0 0 10px rgba($black, .5);

    .icon {
        width: 25%;
        padding: 0 .5em;
        width: 2.5em;
        height: auto;

        &:hover {
            stroke: $warning;
            fill: $warning;
            cursor: initial;
        }
    }

    &-content {
        width: 75%;
    }

    &.warning-label {
        background-color: lighten($warning, 50%);
        border: solid lighten($warning, 20%);

        .icon {
            stroke: $warning;
            fill: $warning;
        }
    }

    &.danger-label {
        background-color: lighten($red, 50%);
        border: solid lighten($red, 20%);

        .icon {
            stroke: $red;
            fill: $red;
        }
    }

    &.info-label {
        background-color: lighten($neonBlue, 25%);
        border: solid $neonBlue;

        .icon {
            stroke: $neonBlue;
            fill: $neonBlue;
        }
    }
}
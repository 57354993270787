.question {
    .text-feedback {
        min-height: 6em;
        text-align: center;

        .content {
            position: relative;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;

            .responses {
                transition: all .5s;
                transform: translateX(calc(-100% * var(--viewing)));
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: flex-start;
                height: max-content;

                .feedback {
                    width: 100%;
                    flex-shrink: 0;
                    padding: 1em;
                    font-size: 18px;
                    white-space: pre-line;
                    height: 50vh;

                    .author {
                        padding: 1em 0;
                        font-style: italic;
                        text-align: right;
                    }
                }
            }
        }

        .controls-wrapper {
            position: sticky;
            height: 4em;
            width: 100%;
            bottom: 0;
            background-color: white;

            .controls {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 1em;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .question {
        .text-feedback {
            .content {
                .responses {
                    .feedback {
                        font-size: 20px;
                    }
                }
            }

            .controls {
                width: 50%;
                margin: 0 auto;
            }
        }
    }
}
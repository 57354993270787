@import "./colors";

.alert-popup {
    position: fixed;
    left: 1em;
    bottom: 0;
    width: 75%;
    background-color: $white;
    padding: 1em;
    text-align: center;
    transition: all 1s;
    transform: translateY(calc(100% + 5em));
    color: $white;
    opacity: 0;
    border-radius: .5em;
    border: 1px solid $grey;
    box-shadow: 2px 2px 6px $grey;
    z-index: 99999;

    &.show {
        transform: translateY(calc(0% - 5em));
        opacity: 1;
    }

    &.info {
        background-color: darken($neonBlue, 40%);
    }

    &.success {
        background-color: $green;
    }

    &.warning {
        background-color: $warning;
    }

    &.error {
        background-color: $red;
    }
}

@media screen and (min-width: 768px) {
    .alert-popup {
        width: 25em;
    }
}

@media screen and (min-width: 1024px) {
    .alert-popup {
        width: 25em;
    }
}

@media screen and (min-width: 1268px) {
    .alert-popup {
        width: 25em;
    }
}
@import "./colors";

@keyframes loading-spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    position: relative;
    width: 3em;
    height: 3em;
    animation: loading-spinner-animation 1.25s infinite linear;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%, 0);
    margin: auto;
    border-radius: 50%;
    // background: $loadingSpinnerColor;
    background: $loadingSpinnerGradient;
    content: ' ';

    &:before {
        content: ' ';
        position: absolute;
        border-radius: 100% 0 0 0;
        background-color: $loadingSpinnerColor;
        width: 50%;
        height: 50%;
        left: 0;
        top: 0;
    }

    &:after {
        content: ' ';
        position: absolute;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        background-color: darken($loadingSpinnerBackground, 10%);
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.loading-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($black, .75);
    height: 100vh;
    z-index: 9999;

    .loading-spinner {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%, 0);
        margin: 0;
    }
}

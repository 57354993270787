@import "./colors";

#admin-integrations {
    width: 100%;

    .integrations {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        .integration {
            position: relative;
            width: 100%;

            .logo {
                position: relative;
                width: auto;
                height: 100%;
                text-align: center;

                svg {
                    position: relative;
                    width: auto;
                    height: auto;
                    max-width: 75%;
                    max-height: 100%;
                }
            }
        }
    }
}

@media screen and ( min-width: 768px) {
    #admin-integrations {
        .integrations {
            .integration {
                width: 50%;
            }
        }
    }
}
.admin-season-selection {
    width: 100%;
    
    .sports{ 
        .sport {
            section {
                margin-bottom: 1em;

                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;

                    li {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .admin-season-selection {
        .sports {
            .sport {
                section {
                    ul {
                        li {
                            width: calc(50% - 1em);
                            margin: 0 .25em;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .admin-season-selection {
        .sports {
            .sport {
                section {
                    ul {
                        li {
                            width: calc(33% - 1.5em);
                            margin: 0 .25em;
                        }
                    }
                }
            }
        }
    }
}
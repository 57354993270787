@import "./colors";

.tooltip-wrapper {
    position: relative;
    width: 100%;

    .tooltip-content {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -3em;
        padding: .25em .75em;
        color: $white;
        background: $darkGrey;
        margin-left: calc(1em * -1);
        white-space: nowrap;
        line-height: 1em;
        border-radius: 4px;
        z-index: 2;

        &:before {
            content: " ";
            position: absolute;
            left: 50%;
            height: 0;
            width: 0;
            top: 100%;
            transform: translateX(-50%);
            border: solid transparent;
            pointer-events: none;
            border-width: 1em;
            border-top-color: $darkGrey;
        }
    }
}
@import "./colors"; 

#org-home {
    .org-name {
        text-align: center;
        background-color: $white;
        width: 100%;
        padding: 0 .5em;
    }

    #org-content {
        padding: .5em;

        .org-menu {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
        }
    }
}

@media screen and (min-width: 1024px) {
    #org-home {
        #org-content {
            .org-menu {
                .card {
                    width: calc(50% - 1em);
                    margin: .5em .25em;
                }
            }
        }
    }
}
@import "./colors";

.card {
    margin: .5em 0;
    padding: .5em;
    width: 100%;
    transition: all .25s;
    border: none;
    line-height: 1.75em;

    &.display-only {
        &:active, &:hover {
            background-color: $white;
            color: $black;
            cursor: initial;
            box-shadow: 2px 2px 6px $lightGrey;
        }
    }

    &-header {
        text-align: center;
    }

    &-footer {
        font-size: .8em;
        font-style: italic;
        text-align: center;
    }
}

.award-certificates {
    .certificate {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@media print {
    * {
        margin: 2mm;
        padding: 0;
    }

    @page {
        margin: 2mm 2mm 2mm 2mm;
        padding: 0;
        size: "letter";
        orientation: landscape;
    }

    header, .org-name, footer { 
        display: none;
    }

    #org-home {
        #org-content {
            padding: 0;
        }
    }
}
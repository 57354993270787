// @mixin button {
//     cursor: pointer;
//     background-color: $buttonBackground;
//     color: $buttonText;

//     &:hover {
//         background-color: $buttonBackgroundHover;
//     }

//     &:active {
//         background-color: $buttonBackgroundActive;
//         color: $buttonBackgroundActiveText;
//     }
// }

@mixin sizeCalculation($element, $max) {
    @for $i from 1 through $max {
        #{$element}:first-child:nth-last-child(#{$i}),
        #{$element}:first-child:nth-last-child(#{$i}) ~ #{$element} {
            max-width: calc((100% / $i) - .5em);
            width: calc((100% / $i) - .5em);
        }
    }
}
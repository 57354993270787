#referee-ratings {
    li {
        padding: .5em;
        text-align: center;

        .referee-rating-scale {
            display: flex;
            align-items: flex-start;
            
            .point-scale {
                flex: 1 0 auto;
                padding: 0 .5em;
                
                input {
                    border:none;
                }
            }

            .side-label {
                line-height: 3.75em;
            }
        }
    }
}
@import "./colors";
@import "./font_faces";

* { 
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 18px;
  color: $textColor;
}

a {
  color: darken($neonBlue, 30%);
}

ul, ol {
  li {
    list-style-type: none;
  }

  &.numbers {
    padding-left: 2em;
    margin: initial;

    li {
      list-style-type: number;
      padding: initial;
      margin: initial;
    }
  }
}

svg {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: $iconColor;
  fill: $iconColor;
  cursor: pointer;
  margin: 0 .25em;
  transition: all .25s;

  &-trophy {
    &.active {
      fill: $iconActive;
    }
  }

  &:hover {
    stroke: $iconHover;
    fill: $iconHover;
  }

  &:active {
    stroke: $iconActive;
    fill: $iconActive;
  }
}

input, select {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: $inputBackground;
  color: $inputText;
  padding: .25em 1em;
  border: none;
  border-bottom: 2px solid $black;
  font-size: 1em;
}

textarea {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1.2rem;
  min-height: 10em;
  padding: 1em;
}

.pagination-buttons {
  text-align: center;
  
  button { 
    width: 10em;
    margin: 0 .5em;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
  visibility: hidden;
}

.full {
  width: 100%;
}

form {
  > div.invalid {
    label {
      color: $red;
    }

    input, select {
      background-color: $red;
      color: $white;
    }
  }
}

sub {
  font-size: 1rem;
  font-style: italic;
  vertical-align: middle;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  &.wrap {
    flex-wrap: wrap;
  }
}

.sticky { 
  position: sticky;
}

.clickable {
  cursor: pointer;
}
@import "./colors";

.checkbox-wrapper {
    position: relative;
    margin: 0 auto;
    text-align: center;

    &:has(.checkbox-field.checkbox) {
        display: flex;
        align-items: center;
        text-align: left;
    }

    label {
        cursor: pointer;
    }

    .checkbox-field {
        position: relative;
        display: inline-block;
        height: 1.5em;
        margin: 0 .5em;

        input {
            display: none;
        }

        &.checkbox {
            position: relative;
            width: 1.5em;

            .checkbox-display {
                display: block;
                position: relative;
                width: 1.5em;
                height: 1.5em;
                background-color: $buttonBackground;
                border: 1px solid $black;
                transition: all .5s;
                cursor: pointer;

                &:before {
                    content: " ";
                    position: absolute;
                    background-color: $buttonBackground;
                    left: .1em;
                    top: .1em;
                    width: 1.25em;
                    height: 1.25em;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                }

                &.checked {
                    transition: all .5s;
                    background-color: $buttonBackgroundSelected;
                }
            }
        }

        &.switch {
            width: 3em;

            .checkbox-display {
                display: block;
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $buttonBackground;
                border: 1px solid $black;
                transition: all .5s;
                border-radius: 34px;
                margin: 0 auto;

                &:before {
                    position: absolute;
                    content: " ";
                    height: 1.2em;
                    width: 1.2em;
                    left: .15em;
                    top: .1em;
                    transition: all .5s;
                    background-color: $scrollbarThumb;
                    border-radius: 50%;
                }

                &.checked {
                    background-color: $buttonBackgroundSelected;

                    &:before {
                        transform: translateX(1.45em);
                    }
                }
            }
        }
    }
}
#season-select {
    .seasonal-player {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .org-logo {
            position: relative;
            flex: 0 1 auto;
            height: 5em;
            width: auto;
            max-height: 5em;
            max-width: 5em;
            margin: .5em 1em;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .card-wrapper {
            flex: 1 0 auto;
        }
    }
}

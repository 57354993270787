@import "./colors";

.admin-team-player {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    padding: .25em 0;

    .player-data {
        width: 100%;
        display: flex;

        .player-name {
            width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .buttons {
            width: 10%;
        }
    }
}
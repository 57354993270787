@import "./colors";

.integration {
    &.leagueapps {
        .logo {
            svg {
                fill: $leagueAppsLogo;
            }

            &:hover {
                svg {
                    fill: lighten($leagueAppsLogo, 10%);
                }
            }
        }

        button {
            width: 100%;

            svg {
                transition: all .25s;
                fill: $white;
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }

            &:hover {
                svg {
                    fill: lighten($leagueAppsLogo, 10%);
                }
            }

            &:active {
                background-color: lighten($leagueAppsLogo, 10%);

                svg {
                    fill: $white;
                }
            }
        }
    }
}

@import "./colors";

.progress-bar-container {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    padding: .5em 0;

    .progress-bar {
        position: relative;
        height: 2em;
        background-color: $purple;
        color: $white;

        &:before {
            content: " ";
            position: absolute;
            height: 2em;
            left: 0;
            width: var(--progress);
            background-color: darken($neonBlue, 10%);
        }

        .icon {
            fill: $white;
        }
    }

    .legend {
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        padding: .5em 0;
        text-shadow: 2px 1px 0px $black;
        line-height: 2em;
        text-align: center;
        color: $white;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

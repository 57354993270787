@import "./colors";

button, .button {
    cursor: pointer;
    background-color: $buttonBackground;
    color: $buttonTextColor;
    padding: .25em .75em;
    margin: 0.5em 0;
    transition: all .25s;
    box-shadow: 2px 2px 6px $lightGrey;
    border: none;
    border-radius: .5em;
    font-size: 18px;
    line-height: 1.75em;

    &.round {
        border-radius: 100%;
    }

    &.success {
        background-color: $green;
        color: $buttonBackgroundActiveText;
    }

    &.fail, &.danger {
        background-color: $red;
        color: $buttonBackgroundActiveText;
    }

    &.selected {
        background-color: $buttonBackgroundSelected;
        color: $buttonBackgroundSelectedText;
        box-shadow: 2px 2px 6px $buttonBackgroundSelected;
    }

    &:hover {
        background-color: $buttonBackgroundHover;
        color: $buttonBackgroundHoverText;
        box-shadow: 2px 2px 6px $buttonBackgroundActive;
    }

    &:active {
        background-color: $buttonBackgroundActive;
        color: $buttonBackgroundActiveText;
        box-shadow: none;
    }

    &:disabled, &.disabled {
        cursor: not-allowed;
        background-color: $buttonBackgroundDisabled;
        color: $buttonBackgroundDisabledText;
        box-shadow: none;
    }
}
$white: #FFFFFF;
$black: #000000;
$green: #487700;
$purple: #5E0B8B;
$grey: #505153;
$darkGrey: #282C34;
$lightGrey: #8D8F91;
$neonBlue: #61BAFB;
$red: #AA2900;
$warning: #A39516;
$leagueAppsLogo: #00812c;

$textColor: $darkGrey;
$backgroundColor: $white;
// $boxShadowColor: rgba($neonBlue, .7);
$boxShadowColor: rgba($grey, .7);

// Button Colors
$buttonBackground: $white;
$buttonTextColor: $black;
$buttonBackgroundHover: lighten($purple, 20%);
$buttonBackgroundHoverText: $white;
$buttonBackgroundActive: darken($neonBlue, 20%);
$buttonBackgroundActiveText: $white;
$buttonBackgroundDisabled: $grey;
$buttonBackgroundDisabledText: $white;
$buttonBackgroundSelected: $purple;
$buttonBackgroundSelectedText: $white;

// Icon Colors
$iconColor: $buttonBackgroundSelected;
$iconHover: $neonBlue;
$iconActive: $buttonBackgroundActive;

$inputBackground: darken($backgroundColor, 5%);
$inputText: $black;

$scrollbarBackground: $purple;
$scrollbarThumb: $neonBlue;

$modalBackground: $backgroundColor;

$loadingSpinnerBackground: $purple;
$loadingSpinnerColor: $neonBlue;
$loadingSpinnerGradient: linear-gradient(to right, $loadingSpinnerColor 10%, rgba($loadingSpinnerColor, 0) 50%);

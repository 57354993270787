@import "./colors";

.draggable-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em;
    margin: .25em 0;
    border: 1px solid rgba($boxShadowColor, .25);
    border-radius: .5em;
    background-color: $white;
    box-shadow: 2px 2px 5px $boxShadowColor;

    &.isDragging {
        z-index: 100;
    }


    .text-display {
        width: calc(100% - 2em);
    }

    aside {
        line-height: .75em;
        height: 100%;
        width: 1.5em;

        .icon {
            width: 100%;
            height: 100%;
        }
    }
}
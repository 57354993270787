@import "../colors";

.textarea-wrapper {
    position: relative;
    width: 100%;

    .textarea-container {
        width: 100%;
        
        textarea {
            width: 100%;
            height: 100%;
            resize: none;
        }
    }

    .textarea-character-length {
        position: relative;
        top: -.4em;
        padding: .5em 1em;
        text-align: right;
        border-top: none;
        border-radius: 0 0 .25em .25em;
        transition: all .5s;

        &.bad {
            background-color: $red;
            color: $white;
        }

        &.good {
            background-color: $green;
            color: $white;
        }
    }
}
@import "./colors";

.admin-questions {
    position: relative;
    width: 100%;
    border-bottom: 1px solid black;

    .question {
        position: relative;
        margin-bottom: 1em;
        width: 100%;

        header {
            position: sticky;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            background-color: $white;
            z-index: 1;
            top: 9.5em;
            width: 100%;
            padding: 0 .25em;
            
            .icon {
                width: 1.5em;
                height: 1.5em;
            }
        }

        .question-info {
            padding: 0 .5em;
        }
    }
}
@font-face {
    font-family: 'Agenor';
    font-weight: 100;
    font-style: normal;
    src:url("../../../fonts/Agenor/Agenor-Thin.ttf");
}

@font-face {
    font-family: 'Agenor';
    font-weight: 300;
    font-style: normal;
    src: url("../../../fonts/Agenor/Agenor-Light.ttf");
}

@font-face {
    font-family: 'Agenor';
    font-weight: 400;
    font-style: normal;
    src: url("../../../fonts/Agenor/Agenor-Regular.ttf");
}

@font-face {
    font-family: 'Agenor';
    font-weight: 700;
    font-style: normal;
    src: url("../../../fonts/Agenor/Agenor-Bold.ttf");
}

@font-face {
    font-family: 'Agenor';
    font-weight: 900;
    font-style: normal;
    src: url("../../../fonts/Agenor/Agenor-Black.ttf");
}
#login {
    width: 100%;
    margin: 0 auto; 

    .prompt {
        // width: 50%;
        margin: 0 auto;
        padding: 2em 0;
    }
}

@media screen and (min-width: 768px) {
    #login {
        width: 75%;
    }
}
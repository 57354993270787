@import "../colors";

#org-settings {
    .card {
        &.show {
            .card-header { 
                cursor: initial;
            }

            .card-content {
                height: auto;
            }
        }
        
        .card-header {
            position: relative;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }

        .card-content {
            transition: all .5s;
            transform-origin: top center;
            overflow: hidden;
            height: 0;
        }
    }

    .collapsible-menu-button {
        position: relative;
        left: .5em;

        &:before {
            transition: all .25s;
            display: inline-block;
            border: .5rem solid black;
            border-bottom: none;
            border-right: none;
            transform: rotateZ(45deg);
            transform-origin: center;
            width: .5em;
            height: .5em;
            content: " ";
        }

        &.expanded {
            &:before {
                transform: rotateZ(225deg);
            }
        }
    }

    .settings-sports {
        ul {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex: auto;

            li {
                width: calc(100% - .25em);
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #org-settings {
        .settings-sports {
            ul {
                li {
                    width: calc(50% - 1em);
                    margin: 0 .5em;
                }
            }
        }
    }
}
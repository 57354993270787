@import "./colors";

#admin-awards {
    width: 100%;

    header {
        .season-select {
            display: block;
            overflow-x: auto;
            position: relative;
            width: 100%;
            white-space: nowrap;
            overflow: -moz-scrollbars-vertical;

            &::-webkit-scrollbar {
                width: .25em;
                height: .5em;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $scrollbarThumb;
                border-radius: 1em;
            }
            &::-webkit-scrollbar-track {
                background: $scrollbarBackground;
            }

            .card {
                display: inline-flex;
            }
        }
    }

    .sections {
        position: relative;
        width: 100%;

        .missing-questions {
            padding: 2em;
        }

        section {
            margin-bottom: 1em;
            box-shadow: 0px 0px 10px $boxShadowColor;
            min-height: 5em;

            .section-title {
                padding: .5em .25em;
                text-align: center;
            }

            .new-question {
                text-align: center;
                padding: .5em;
            }
        }
    }

    .non-voters {
        position: relative;
        height: auto;

        h2 {
            text-align: center;
            padding: .5em;
            cursor: pointer;

            > div {
                display: inline-block;
            }

            .collapsible-menu-button {
                margin: 0 .5em;
                
                &:before {
                    transition: all .25s;
                    display: inline-block;
                    border: .5rem solid black;
                    border-bottom: none;
                    border-right: none;
                    transform: rotateZ(45deg);
                    transform-origin: center;
                    width: .5em;
                    height: .5em;
                    content: " ";
                }
        
                &.expanded {
                    &:before {
                        transform: rotateZ(225deg);
                    }
                }
            }
        }

        .card-content {
            text-align: center; 
            
            .content-hide {
                transition: all .5s;
                height: 0;
                overflow: hidden;
            }

            .question-votes {
                align-items: stretch;
            }
        }
    }
}